.ehs-modal-span {
  position: relative;
  top: 4pt;
}

.help-button {
  position: relative;
  float: right;
  cursor: pointer;
}

.download-button {
  position: relative;
  margin-right: 10px !important;
  float: right;
  cursor: pointer;
}

.attachments-row {
  margin-top: 20px;
}

.clos-selected-field {
  margin-top: 5px;
  cursor: pointer;
  z-index: 1;
}

.clo-chip {
  cursor: pointer !important;
}

#attachments-box {
  margin-top: 10px;
}

#attachments-label {
  padding-left: 10px;
}

.GAI-list-item {
  overflow: visible !important;
  white-space: normal !important;
  line-height: 1 !important;
}
