.react-grid-Container {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14.625px;
    height: 100%;
    padding: 10px;
}

.react-grid-Main {
    height: 100%;
}

.react-grid-Grid {
    height: 100%;
}