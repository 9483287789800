.errorText {
    color: red;
}

.black {
    background-color: #000000;
}

.ink {
    background-color: #062F4F !important;
    color: white !important;
}

.oxblood {
    background-color: #76323F;
}

.embers {
    background-color: #B82601 !important;
}

.grain {
    background-color: #D7CEC7;
}

.whiteText {
    color: white !important;
}

.powder-blue{
    background-color: #E8EBF5;
}

.edit-blue{
    color: #0363d4;
}

.fairPinkBackground {
    background-color: #FFE9E9;
}

.oxBloodText {
    color: #B82601;
}

.waikawa-gray {
    background-color: #657697 !important;
    color: white !important;
}

.riverBed{
    color: #424858 !important;
}

.beesWaxBackground {
    background-color: #fef9c7;
}

.buddhaGold {
    color: #ae9d03;
}

.turboYellow {
    color: #f4dc04;
}