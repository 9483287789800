div.courseWrapper {
    border: solid 1px #CCCCCC;
    margin-bottom: 10px;
}

div.headerRoot {
    padding-top: 5px;
    padding-bottom: 5px
}

span.headerContent {
    padding-left: 20px
}