#about-me{
    border-right: 1px solid lightgray;
}

.update-pic-container {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 20%;
    left: 20%;
/*    transform: translate(-60%, -60%);
    -ms-transform: translate(-60%, -60%);*/
    text-align: center;
    cursor: pointer;
    display: inline-block;
} 

.personal-info{
  text-align: center;
} 

.profile-pic-container:hover .profile-pic {
  opacity: 0.3;
}

.profile-pic-container:hover .update-pic-container {
  opacity: 1;
}

@media only screen and (max-width : 576px) {
    .personal-info{
        text-align: center;
    }
    #about-me{
        border-right: none;
    }
    #profile-pic{
      margin: 0 auto;
    }
  }
