#lock-assessment-label {
    position: relative;
    top: 12px;
    line-height: 0.7;
    display: inline-block;
    text-align: left;
    font-size: 1.3rem;
    font-weight: 700;
    margin-left: 8px;
}

#lock-assessment-label span {
    font-size: 80% !important;
    font-weight: normal;
}

.bottom-box {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

.bottom-box#samples-of-student-work {
    text-align: center;
}

.bottom-box#question-grid {
    margin-bottom: 0px;
}