html, body, div, td, li {
    color: #444444;
}

html {
    font-size: 18px !important;
}

body {
    font-size: 18px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif
}

.row:before {
    width: 100%;
}

.ehs-error-icon {
    background-color: transparent;
    color: black;
}

.ehs-error {
    margin: 0;
    font-size: 0.75rem;
    margin-top: 8px;
    min-height: 1em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1em;
    color: #f44336;
}

.paperBackground {
    background-color: rgb(242, 243, 248)
}

.pointerCursor {
    cursor: pointer;
}

.arrowCursor {
    cursor: default;
}

div.center {
    width: 100%;
    text-align: center;
}

button.ehs-position-right {
    right: 20px !important;
    position: absolute !important;
}

button.ehs-add-button-wide {
    background-color: black !important;
    margin: "theme.spacing.unit" !important;
    width: 125px !important;
}

button.ehs-add-button {
    background-color: black !important;
}

button.hollow-button {
    border: 1px solid black;
    background-color: white;
    border-radius: 4px;
    color: black;
    padding: 6px 8px;
    font-size: 16px;
    cursor: pointer;
    font-family: "Helvetica";
}

.hollow-ember {
    border-color: rgb(174, 53, 26) !important;
    color: rgb(174, 53, 26) !important;
}

.hollow-ember:hover {
    background-color: rgb(174, 53, 26) !important;
    color: white !important;
}

div.full-height {
    height: 100%;
}

.m-header--fixed-mobile .m-body {
    padding-top: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

span.no-wrap {
    white-space: nowrap;
}

div.total-counts {
    text-align: center;
}

h2.m-portlet__head-label {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

div.m-portlet.m-portlet--creative .m-portlet__head {
    height: 0;
}

span.nowrap {
    white-space: nowrap;
}

.white-text {
    color: white;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.no-padding-right {
    padding-right: 0;
}

.no-padding-left {
    padding-left: 0;
}

.padding-right-10{
    padding-right: 10;
}

img.preview {
    width: 80px;
    height: 80px;
    border: 1px solid lightgray;
    border-radius: 3px;
    margin-bottom: 5px;
}

.hide {
    display: none;
}

.actionIcon {
    background-color: transparent;
    cursor: pointer;
}

.fullImageStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh
}

.fullImageStyle img {
    max-width: 70%;
}

#close-image-button {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    cursor: pointer;
}

.center {
    text-align: center;
}

td.right {
    text-align: right;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%
}

div.disabled-text>div>div>textarea {
    color: #AAAAAA;
}

.break-word {
    word-break: break-word;
}

.spacing-top {
    position: relative;
    top: 7px;
}

.spacing-top20 {
    position: relative;
    top: 20px;
}

.center-wrapper {
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    height: calc(100% - 64px);
}

.center-div {
    position: relative;
    top: -50%;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
}

.top-left-button {
    width: 200px;
    height: 100%;
    text-transform: capitalize !important;
	margin-left: 20px !important;
	margin-right: 20px !important;
	color: 
}

.appBar {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.report-button {
    width: 100%;
    margin-top: 5px !important;
}

.expansionPanelSpacing {
    margin-bottom: 5px;
}

.no-content {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 400;
}

/*JExcel CSS*/

.jexcel>thead>tr>td {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    background-color: #ffffff !important;
    font-weight: bold !important;
    font-size: 14px !important;
    font-family: "Helvetica", Arial, sans-serif !important;
}

.jexcel>tbody>tr>td {
    border-right: 1px solid #ccc;
    border-left: none !important;
}

.jexcel .highlight-selected {
    padding: 0px !important;
}

.jexcel>tbody>tr>td {
    padding: 8px !important;
    font-size: 14px !important;
    font-family: "Helvetica", Arial, sans-serif !important;
}

.jexcel>tbody>tr>td:first-child {
    background-color: #ffffff !important;
    border-left: 1px solid #ccc !important;
}

.jexcel {
    border-right: none !important;
}

.times-taught>div>input {
    padding: 0;
    text-align: center;
}

.font23{
    font-size: 23px;
}

.font20{
    font-size: 20px;
}

.font19{
    font-size: 19px;
}

.font18{
    font-size: 18px;
}

.font16{
    font-size: 16px;
}

.font14{
    font-size: 14px;
}

.font12{
    font-size: 12px;
}

.bold{
    font-weight: bold;
}

.semibold{
    font-weight: 600;
}

.normalFontWeight{
    font-weight: normal !important;
}

.poppinFont{
    font-family: "Poppins";
}

.inlineBlock{
    display: inline-block;
}

.cloGaiContentLevel-chip{
    color: white !important;
    background-color: #B82601 !important;
    margin-right: 5px !important;
    font-size: 12px !important;
    font-family: "Poppins" !important;
}

.margin-topbottom-5{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.margin-right-10{
    margin-right: 10px !important;
}

.margin-right-20{
    margin-right: 20px !important;
}

.margin-top-15{
    margin-top: 15px !important;
}

.margin-top--25{
    margin-top: -25px !important;
}

.margin-top-50{
    margin-top: 50px !important;
}

.margin-bottom-30{
    margin-bottom: 30px !important;
}

.padding-top-10{
    padding-top: 10px !important;
}

.padding-top-20{
    padding-top: 20px !important;
}

.padding-top-50{
    padding-top: 50px !important;
}

.padding-bottom-20{
    padding-bottom: 20px !important;
}

.padding-right-20{
    padding-right: 20px !important;
}