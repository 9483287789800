div.courseWrapper {
    border: solid 1px #CCCCCC;
    margin-bottom: 10px;
    cursor: pointer;
}

div.headerRoot {
    padding-top: 5px;
    padding-bottom: 5px;
	display: flex;
	height: 42px;
}

span.headerContent {
    padding-left: 20px;
    color: white;
}

span.marginLeft5 {
    margin-left: 5px;
}

span.marginRight5 {
    margin-right: 5px;
}

.white {
    color: white;
}

div.subContentRoot {
    padding-left: 10px;
}

.subContent {
    margin-right: 10px;
    white-space: nowrap;
}