@media (max-width: 767px) {

    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        /* padding-top: 50px; */
    }
}

@media (max-width: 575px) {

    html,
    body {
        font-size: 12px !important
    }
}