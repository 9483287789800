div.spacer{
    margin-bottom: 10px;
}

.ga-ocurrences{
    padding: 3px 0px;
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
    .ga-ocurrences button{
        font-size: 80%;
    }
}
