#m_login_signin_submit {
    color: white !important;
    background-color: black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.m-login.m-login--2.m-login-2--skin-2 
.m-login__container 
.m-login__form .form-control{
    color: #000000;
}