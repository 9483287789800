#backOneStepBack {
    margin: 10px;
    background-color: #B82601;
    color: white !important;
    min-width: 250px;
}

#backOneStepBack:disabled {
    opacity: 0.5;
}

#backOneStepBack>span:nth-child(1) {
    position: relative;
    left: -85px;
}