span.courseMasterAttributes {
    margin-left: 5px;
}

div.smallSpaces {
    padding: 3px;
}

div.thick-border {
    border-bottom: solid 3px black;
}

div.thin-border {
    border-bottom: solid 1px #eeeff2;
}

div.smallVerticalSpacing {
    padding-top: 10px;
    padding-bottom: 10px;
}

div.adjust-middle {
    position: relative;
    top: 10px;
}

div.span-to-center>div>div>input {
    text-align: center;
}

div.no-padding-input>div>input {
    padding-bottom: 0;
}

.courseOutlinePadding {
    padding: 7px 5px;
}

.hrTitle{
    border: 1px solid #424858;
}

.hrTable
{
    width: 100% !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.hrClo{
    border: 1px solid #02428e;
    width: 65% !important;
    margin-right: 27px !important;
    margin-top: 15px !important;
    margin-bottom: 25px !important;
}

.hrGai{
    width: 100% !important;
    margin-right: 27px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.cloButton{
    color: white !important;
    background-color: #012147 !important;
    font-size: 14px !important;
    font-family: "Poppins" !important;
    padding: 9px 22px !important;
}

.cloButtonSelected{
    color: white !important;
    background-color: #02428e !important;
    font-size: 14px !important;
    font-family: "Poppins" !important;
    padding: 9px 22px !important;
}