.clo-outcome {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
}

.clo-suboutcome {
  font-size: 14px;
}

.clo-checkbox {
  padding-top: 5px;
}

.outer-box {
  display: block;
  align-items: center;
  max-width: 450px;
  overflow-y: hidden;
  margin-left: 10px;
  border-bottom: 1px solid lightgray;
}

.checkbox {
  margin-top: -5px !important;
}

.checkbox input[type=checkbox] {
  margin-left: 0px !important;
}