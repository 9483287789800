.sub-text{
    font-size: 75%;
    padding-top: 10px;
    font-weight: normal;
}

#lock-assessment-label {
    position: relative;
    top: 12px;
    line-height: 0.9;
    display: inline-block;
    text-align: left;
    font-size: 1.3rem;
    font-weight: 700;
    margin-left: 8px;
    margin-right: 20px;
}

#lock-assessment-label span {
    font-size: 14px !important;
    font-weight: normal;
}

#lock-assessment-box {
    width: 100%;
    margin-top: 10px;
    text-align: 'center';
    border: 1px solid #B82601;
    border-radius: 4px;
    text-align: center;
}

.bottom-box {
    width: 100%;
    margin-top: 20px;
}

.bottom-box#samples-of-student-work {
    text-align: center;
}

.bottom-box#question-grid {
    margin-bottom: 0px;
}