header {
    background-color: transparent !important;
}

button {
    color: black !important;
}

.avatar {
    margin: 10;
    cursor: pointer;
}